import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Accordion from 'react-bootstrap/Accordion';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Faq extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='FAQ' />
                
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">FAQ</h2>
                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                <div className="ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 custom-accordion">
                                <h2 className="text-center">FAQ</h2>
                                <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>How much does a WordPress website cost to make?</Accordion.Header>
        <Accordion.Body>
        The cost of creating a WordPress website varies, considering several key factors. These include the expertise of the WordPress agency, the complexity of your unique website design, UX expert inputs for an optimal user experience, and the specific requirements of your new website. Additionally, market competition and your target audience’s needs also play a crucial role in shaping the overall investment for your WordPress project.

Key variants

How many pages do you require
The style of your website compared to your competitors
The functionality of your website
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Are your websites built for mobile devices?</Accordion.Header>
        <Accordion.Body>
        Yes, all our websites are built for mobile devices. Having a responsive website is crucial and has become standard practice. Our web designers create websites with responsive devices in mind, ensuring the design is adaptive to the screen dimensions.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Can you help me with my WordPress website if someone else built it?</Accordion.Header>
        <Accordion.Body>
        We typically offer dedicated support for websites built by other agencies, especially if you have Admin access. Leveraging the open-source platform of WordPress, we work closely with you to enhance and build websites, providing expert web design and development services, irrespective of the original developer.Our approach ensures seamless integration with your existing digital infrastructure.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>When should I choose a bespoke WordPress web design over a premium theme?</Accordion.Header>
        <Accordion.Body>
        Choose a premium theme for a cost-effective, quick solution that doesn’t compromise on quality or functionality, ideal for small business owners wanting the best website quickly. For a more personalized touch, bespoke WordPress web design, crafted by UX experts, offers unique features tailored to your needs. Although it requires a more significant investment, a custom build ensures a competitive edge, enhancing your online presence significantly.
        </Accordion.Body>
      </Accordion.Item>
                                </Accordion>

                            </div>
                        </div>
                    </div>
                </div>
                
                <Footer />
                
            </React.Fragment>
        )
    }
}
export default Faq