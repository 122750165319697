import React, { useEffect,  useState } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import PortfolioList from "../elements/portfolio/PortfolioList";
import WordpressPortfolioList from "../elements/portfolio/WordpressPortfolioList";
import EcommercePortfolioList from "../elements/portfolio/EcommercePortfolioList";
import WebdesignPortfolioList from "../elements/portfolio/WebdesignPortfolioList";
import CustomphpPortfolioList from "../elements/portfolio/CustomphpPortfolioList";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useLocation, useHistory } from "react-router-dom";
const Portfolio = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [active, setActive] = useState("all");

  const history = useHistory();
  const location = useLocation();
  const Tabsname = ["all", "wordpress", "ecommerce", "webdesign", "customphp"];
  useEffect(() => {
    const path = location.pathname.split("/").pop();
    if (path === "portfolio") {
    } else {
      if (Tabsname.includes(path)) {
        setActive(path);
        setTabIndex(Tabsname.indexOf(path));
      }

      history.push(`/portfolio/${path}`);
    }
  }, [location.pathname, history]);

  const handleClick = (id) => {
    history.push(`/portfolio/${id}`);
    setActive(id);
    setTabIndex(
      ["all", "wordpress", "ecommerce", "webdesign", "customphp"].indexOf(id)
    );
  };

  return (
    <>
      <PageHelmet pageTitle="Counters" />

      {/* Start Header Area  */}
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />
      {/* End Header Area  */}

      {/* Start Breadcrump Area */}
      <Breadcrumb title={"Portfolio"} bg_image={"bg-image-5"} />
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">
        {/* Start Portfolio Area */}
        <div className="portfolio-area ptb--120" id="all">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row text-center">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30">
                    <p className="sub-heading-text">
                      CHOOSE YOUR PREFERENCE TO START A PROJECT WITH US.
                    </p>
                    <div className="title custom-heading h2">
                      DISCOVERING SPECIAL PEOPLE.
                    </div>
                    <div className="title custom-heading h2">
                      PRODUCING EXTRAORDINARY PORTFOLIO
                    </div>
                  </div>
                </div>
              </div>

              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <div className="row justify-content-center">
                  <div className="col-lg-12 text-center">
                    <div className="tablist-inner portfolio-custom-menu">
                      <TabList className="pv-tab-button text-center mt--0">
                        <Tab
                          id="all"
                          className={`tab-button ${
                            active === "all"
                              ? "active react-tabs__tab--selected"
                              : ""
                          }`}
                          onClick={() => handleClick("all")}
                        >
                          <span>All</span>
                        </Tab>

                        <Tab
                          id="wordpress"
                          className={`tab-button ${
                            active === "wordpress"
                              ? "active react-tabs__tab--selected"
                              : ""
                          }`}
                          onClick={() => handleClick("wordpress")}
                        >
                          <span>Wordpress</span>
                        </Tab>
                        <Tab
                          id="ecommerce"
                          className={`tab-button ${
                            active === "ecommerce"
                              ? "active react-tabs__tab--selected"
                              : ""
                          }`}
                          onClick={() => handleClick("ecommerce")}
                        >
                          <span>E-Commerce</span>
                        </Tab>
                        <Tab
                          id="webdesign"
                          className={`tab-button ${
                            active === "webdesign"
                              ? "active react-tabs__tab--selected"
                              : ""
                          }`}
                          onClick={() => handleClick("webdesign")}
                        >
                          <span>Web Design</span>
                        </Tab>
                        <Tab
                          id="customphp"
                          className={`tab-button ${
                            active === "customphp"
                              ? "active react-tabs__tab--selected"
                              : ""
                          }`}
                          onClick={() => handleClick("customphp")}
                        >
                          <span>Custom PHP</span>
                        </Tab>
                      </TabList>
                    </div>
                  </div>
                </div>

                <TabPanel className="row portfolio-row">
                  <PortfolioList
                    styevariation="text-center mt--40"
                    column="col-lg-4 col-md-6 col-sm-6 col-12"
                    item="100"
                  />
                </TabPanel>

                <TabPanel className="row portfolio-row">
                  <WordpressPortfolioList
                    styevariation="text-center mt--40"
                    column="col-lg-4 col-md-6 col-sm-6 col-12"
                    item="100"
                  />
                </TabPanel>

                <TabPanel className="row portfolio-row">
                  <EcommercePortfolioList
                    styevariation="text-center mt--40"
                    column="col-lg-4 col-md-6 col-sm-6 col-12"
                    item="100"
                  />
                </TabPanel>

                <TabPanel className="row portfolio-row">
                  <WebdesignPortfolioList
                    styevariation="text-center mt--40"
                    column="col-lg-4 col-md-6 col-sm-6 col-12"
                    item="100"
                  />
                </TabPanel>

                <TabPanel className="row portfolio-row">
                  <CustomphpPortfolioList
                    styevariation="text-center mt--40"
                    column="col-lg-4 col-md-6 col-sm-6 col-12"
                    item="100"
                  />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>

        {/* End Portfolio Area */}
      </main>
      {/* End Page Wrapper  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
    </>
  );
};

export default Portfolio;
