import React, { Component } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list ${branstyle}`}>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">C#</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/microsoft/c--4.svg" alt="c" width="71" height="80"/>  
                    </li>
                    </OverlayTrigger>
                    
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">ASP.NET</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/microsoft/aspnet-svgrepo-com.svg" alt="aspnet" width="80" height="80"/>
                    </li>
                    </OverlayTrigger>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;