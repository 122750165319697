import React, { Component } from "react";
import { brandSlick } from "../../src/page-demo/script";
import Slider from "react-slick";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
               
                <div className="custom-heading title text-center h2 sec-big-h2">ECSTATIC CLIENTS</div>
                <Slider className="brand-slider technologies-grid" {...brandSlick}>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-1.jpg" alt="Logo Images" width="190" height="149" loading="lazy"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-2.jpg" alt="Logo Images" width="190" height="149" loading="lazy"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-3.jpg" alt="Logo Images" width="190" height="149" loading="lazy"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-4.jpg" alt="Logo Images" width="190" height="149" loading="lazy"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-5.jpg" alt="Logo Images" width="190" height="149" loading="lazy"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-6.jpg" alt="Logo Images" width="190" height="149" loading="lazy"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-7.jpg" alt="Logo Images" width="190" height="149" loading="lazy"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-8.jpg" alt="Logo Images" width="190" height="149" loading="lazy"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-9.jpg" alt="Logo Images" width="190" height="149" loading="lazy"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-10.jpg" alt="Logo Images" width="190" height="149" loading="lazy"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-11.jpg" alt="Logo Images" width="190" height="149" loading="lazy"/>
                    </li>
                </ul>
                </Slider>
            </React.Fragment>
        )
    }
}
export default BrandTwo;