import React, { Component } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list ${branstyle}`}>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">GitHub</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/code/github-icon-1-logo-svgrepo-com.svg" alt="github" width="80" height="80"/>    
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Bitbucket</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/code/bitbucket-svgrepo-com.svg" alt="bitbucket" width="80" height="80"/>
                    </li>
                    </OverlayTrigger>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;