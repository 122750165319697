import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import CounterOne from "../elements/counters/CounterOne";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { testimonialSlick } from "../../src/page-demo/script";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import bgimgs from "../../public/assets/images/bg/bg-image-24.jpg";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageLoaded: false,
    };
  }
  render() {
    let title = "About",
      description =
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going.";
    return (
      <React.Fragment>
        <PageHelmet pageTitle="About Us " />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        {/* <Breadcrumb style={{ backgroundColor: 'red', color: 'red' }} title={'About Us'} bg_image={"bg-image-24"}   /> */}
        <div
          className="breadcrumb-area rn-bg-color ptb--120"
          style={{
            // backgroundImage: `url(${bgImageUrl})`,
            // backgroundImage: `url(${bgimgs})`,
            backgroundImage: this.state.imageLoaded ? `url(${bgimgs})` : "none",
            backgroundColor: this.state.imageLoaded ? "transparent" : "#f0f0f0", // Placeholder color
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "200px", // Adjust as needed
          }}
          data-black-overlay="6"
        >
          {!this.state.imageLoaded && (
            <div style={{ minHeight: "200px" }}></div>
          )}{" "}
          {/* Placeholder */}
          <img
            src={bgimgs}
            alt="Background"
            style={{ display: "none" }}
            onLoad={() => this.setState({ imageLoaded: true })}
          />
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inner pt--100">
                  <h1 className="title h2">About Us</h1>
                  <ul className="page-list">
                    <li className="breadcrumb-item" rel="preload">
                      <Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active">About Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/about/image-right.jpg"
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">
                        Building a Website With the Power to Fuel Growth
                      </h2>
                      <p className="description"></p>
                    </div>
                    <div className="row mt--30">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <div className="title h3-title">
                            Cutting-Edge Framework
                          </div>
                          <p>
                            We provide the technology to drive your site to
                            success with use of latest frameworks.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <div className="title h3-title">
                            Customized Approach
                          </div>
                          <p>
                            Complete customization in real time. So every
                            element of your site looks just the way you want it.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area pb--60 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <div className="text-uppercase title custom-heading h2">
                    Our Cheerful Facts
                  </div>
                </div>
              </div>
            </div>
            <CounterOne />
            <div className="pb-4"></div>
            <div className="mt-4"></div>
            <div className="fontWeight400 mt-md-4 mb-md-4 mb-0 text-center pra light-color h3">
              ....and Still Counting!!
            </div>
          </div>
        </div>
        {/* End CounterUp Area */}

        {/* Start Finding Us Area  */}
        <div className="rn-finding-us-area rn-finding-us bg_color--1">
          <div className="inner">
            <div className="content-wrapper">
              <div className="content">
                <div className="theme-gradient h4">ABOUT RIGIC GLOBAL</div>
                <p>
                  We are web designers, technology experts and digital marketers
                  — all working hard to help you grow your business. Our team
                  and strategists work collaboratively to deliver cutting-edge
                  websites that not only look stunning but also perform
                  seamlessly across all devices. Whether you're a small business
                  looking to establish your online presence or a large
                  corporation in need of a complex web application, we have the
                  expertise to bring your vision to life.
                </p>
                <a
                  aria-label="get-started"
                  className="rn-btn btn-white started-btn"
                  href="/contact"
                >
                  Get Started
                </a>
              </div>
            </div>
            <div className="thumbnail">
              <div className="image">
                <img
                  src="/assets/images/about/about-banner.jpg"
                  alt="Finding Images"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Finding Us Area  */}

        {/* Start Team Area  */}
        <div className="rn-team-area bg_color--1 ptb--120 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 mt-0 team-intro">
                  <p className="sub-heading-text">TEAMWORK BUILDS TRUST</p>
                  <div className="title custom-heading h2">CREATIVE PEOPLE</div>

                  <p>
                    A lot of young players don't really know much about the
                    history of the game and a lot of them are missing out on
                    what the game is all about, especially the whole concept of
                    sportsmanship and teamwork.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              {/* Start Single Team  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team-wrap">
                  <div className="team">
                    <div className="thumbnail">
                      <img
                        className="w-100"
                        src="/assets/images/team/keyur-frame.jpg"
                        alt="KEYUR PATEL"
                        width="390"
                        height="437"
                      />
                    </div>
                    <div className="content">
                      <div className="title h4">KEYUR PATEL</div>
                      <p className="designation">CO-FOUNDER & CTO</p>
                      <p className="designation team-skills">
                        With great experience in multiple technologies, Keyur
                        stands tall in terms of leveraging the in-house projects
                        with innovation and technical proficiency. On the edge
                        of his key expertise, we guarantee clients – the optimum
                        satisfaction.
                      </p>
                    </div>
                    <div className="text-center mt-3">
                      <div className="title mb-0 h4">KEYUR PATEL</div>
                      <p className="designation">CO-FOUNDER & CTO</p>
                    </div>
                    {/* <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul> */}
                  </div>
                </div>
              </div>
              {/* End Single Team  */}

              {/* Start Single Team  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="team-wrap">
                  <div className="team">
                    <div className="thumbnail">
                      <img
                        className="w-100"
                        src="/assets/images/team/nikhil-frame.jpg"
                        alt="NIKHIL JAKHOTIA"
                        width="390"
                        height="437"
                      />
                    </div>
                    <div className="content">
                      <div className="title h4">NIKHIL JAKHOTIA</div>
                      <p className="designation">CO-FOUNDER & CEO</p>
                      <p className="designation team-skills">
                        From holding the Marketing reins of Big Balance Sheet
                        brands to creating satisfaction for all customer contact
                        points, Nikhil is well-versed in developing high-quality
                        business strategies and plans. He always sparks
                        emotional connections with a user-first approach.
                      </p>
                    </div>
                    <div className="text-center mt-3">
                      <div className="title mb-0 h4">NIKHIL JAKHOTIA</div>
                      <p className="designation">CO-FOUNDER & CEO</p>
                    </div>
                    {/* <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul> */}
                  </div>
                </div>
              </div>
              {/* End Single Team  */}

              {/* Start Single Team  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mx-auto">
                <div className="team-wrap">
                  <div className="team">
                    <div className="thumbnail">
                      <img
                        className="w-100"
                        src="/assets/images/team/new-frame.jpg"
                        alt="NAMRATA JAKHOTIA"
                        width="390"
                        height="437"
                      />
                    </div>
                    <div className="content">
                      <div className="title h4">NAMRATA JAKHOTIA</div>
                      <p className="designation">CREATIVE DIRECTOR</p>
                      <p className="designation team-skills">
                        Namrata has been with Rigic since its beginning. She
                        helps defining brands and creating the experiences. Her
                        content strategies integrates the best fit with design.
                      </p>
                    </div>
                    <div className="text-center mt-3">
                      <div className="title mb-0 h4">NAMRATA JAKHOTIA</div>
                      <p className="designation">CREATIVE DIRECTOR</p>
                    </div>
                    {/* <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul> */}
                  </div>
                </div>
              </div>
              {/* End Single Team  */}
            </div>
          </div>
        </div>
        {/* End Team Area  */}

        {/* Start Testimonial Area */}
        {/* <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div> */}
        <div className="rn-testimonial-area pb--120 testimonial-slider text-center">
          <div className="container">
            <span className="sub-heading-text">WHAT PEOPLE SAY</span>
            <div className="title custom-heading h2">TESTIMONIALS</div>
            <Slider className="brand-slider" {...testimonialSlick}>
              <div className="testimonial-content text-center">
                <p>
                  We gave the team a serious challenge on the size of the
                  project and how long they had to build it. They achieved all
                  the goals we set out. Great job. Already worked on multiple
                  projects at the same time since long and getting exceptional
                  results always. Go for them without a doubt!
                </p>
                <div className="author-info">
                  <div className="h6">
                    <span>-GEORGE AFFLECK , </span>Curve Communication
                  </div>
                </div>
              </div>
              <div className="testimonial-content text-center">
                <p>
                  Best work I've found so far! I highly recommend Rigic Global
                  Solutions and I will be using them for my online development
                  in the future! My brand is 100x more visible and able to grow
                  because of the well done and intuitive enhancements from RGS.
                </p>
                <div className="author-info">
                  <div className="h6">
                    <span>-Big Yen Music</span>
                  </div>
                </div>
              </div>
              <div className="testimonial-content text-center">
                <p>
                  I had a wonderful experience working with Rigic Global
                  Solutions. This team was efficient, helpful, and quick to
                  respond. They were able to help me with the exact problems I
                  wanted to fix and projects I wanted accomplished. They knew I
                  was working on a deadline and made sure to work alongside me
                  to get things done and make sure I was happy. I can highly
                  recommend this team and would work with them in the future
                  without hesitation.
                </p>
                <div className="author-info">
                  <div className="h6">
                    <span>-Alex Jordan</span>
                  </div>
                </div>
              </div>
              <div className="testimonial-content text-center">
                <p>
                  Rigic &amp; team were able to quickly deliver high-quality
                  code under a tight deadline. Communication and availability
                  were excellent so we were able to revise and implement any
                  necessary edits/changes efficiently and effectively so as not
                  to delay the project timeline. We enjoyed working with them
                  and hope to work on another project soon.
                </p>
                <div className="author-info">
                  <div className="h6">
                    <span>-Jack Finio</span>
                  </div>
                </div>
              </div>
              <div className="testimonial-content text-center">
                <p>
                  This is the second website that they have done for my
                  business. And, again we are delighted. We will definitely
                  return for any Wordpress sites we need. They are prompt,
                  efficient and very easy to work with.
                </p>
                <div className="author-info">
                  <div className="h6">
                    <span>-Sharifa Salaam</span>
                  </div>
                </div>
              </div>
              <div className="testimonial-content text-center">
                <p>
                  Professional, Great english, Very responsive. They are
                  great.Team was there to explain and make sure we understand it
                  all trough the process. Came with great ideas and help to
                  improve the product.
                </p>
                <div className="author-info">
                  <div className="h6">
                    <span>-The Pick App Market Place</span>
                  </div>
                </div>
              </div>
              <div className="testimonial-content text-center">
                <p>
                  This job was completed with ease and in a very professional
                  manner. Rigic Global Solutions was very helpful, timely and
                  professional. They worked around my issues with ease and even
                  had feedback to improve upon my website and brand visibility.
                </p>
                <div className="author-info">
                  <div className="h6">
                    <span>-James Frolio</span>
                  </div>
                </div>
              </div>
              <div className="testimonial-content text-center">
                <p>
                  I strongly recommend to hire this company. They did very
                  fabulous job for me. I am kindly a strong and hard person but
                  so far I worked with this company, they did exactly what I
                  required them to do.
                </p>
                <div className="author-info">
                  <div className="h6">
                    <span>-John Brighton</span>
                  </div>
                </div>
              </div>
              <div className="testimonial-content text-center">
                <p>
                  I can't tell you how organized and on time they were with our
                  project. I will use Rigic over and over. You can't go wrong
                  with someone who communicates clearly and gets the job done as
                  stated and quoted.
                </p>
                <div className="author-info">
                  <div className="h6">
                    <span>-Boris Gonzalez</span>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
        {/* End Testimonial Area */}

        {/* Start Brand Area */}
        <div className="rn-brand-area brand-separation custom-script pb--120 ">
          <div className="container">
            <div className="start-project-wrapper">
              <div className="row">
                <div className="col-lg-8 col-md-6 col-sm-8 col-xs-12">
                  <div className="text-black margin-5px-bottom text-color-white h3">
                    Let's make something great together
                  </div>
                  <p>
                    Get in touch with us and send some basic info for a quick
                    quote
                  </p>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-4 col-xs-12 text-left">
                  <a
                    aria-label="start-project"
                    className="rn-button-style--2 btn-solid"
                    href="/contact#contact-form"
                  >
                    Start a project
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default About;
