import React, { Component } from "react";
import { brandSlick } from "../../src/page-demo/script";
import Slider from "react-slick";
import { MdBusinessCenter } from "react-icons/md";
import { FaNotesMedical } from "react-icons/fa";
import { IoMdSchool } from "react-icons/io";
import { FaMobile } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { FaPizzaSlice } from "react-icons/fa";
import { GoLaw } from "react-icons/go";
import { FaShoppingCart } from "react-icons/fa";
import { RiComputerFill } from "react-icons/ri";
import { IoMdCar } from "react-icons/io";
import { IoMdSettings } from "react-icons/io";
import { FaPlane } from "react-icons/fa";
import { MdHotel } from "react-icons/md";
import { GrGraphQl } from "react-icons/gr";
import { MdEventAvailable } from "react-icons/md";
import { GoGraph } from "react-icons/go";
import { FaHandHoldingHeart } from "react-icons/fa";
import { IoIosRocket } from "react-icons/io";
import { GiShop } from "react-icons/gi";
import { GiTv } from "react-icons/gi";


class Industries extends Component{
    render(){
        return(
            <React.Fragment>

                <div className="title h2 custom-heading color-white text-center">INDUSTRIES SERVED</div>
                <Slider className="brand-slider" {...brandSlick}>
                <ul className="brand-style-2">
                    <li>
                        <MdBusinessCenter />
                        <span className="d-block mt-2 color-white h4">Business</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <FaNotesMedical />
                        <span className="d-block mt-2 color-white h4">Hospital</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <IoMdSchool  />
                        <span className="d-block mt-2 color-white h4">Education</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <FaHandHoldingHeart />
                        <span className="d-block mt-2 color-white h4">NPO’s</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <FaMobile  />
                        <span className="d-block mt-2 color-white h4">Technology</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <FaBuilding   />
                        <span className="d-block mt-2 color-white h4">Real Estate</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <FaPizzaSlice />
                        <span className="d-block mt-2 color-white h4">Food</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <GoLaw  />
                        <span className="d-block mt-2 color-white h4">Law</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <FaShoppingCart />
                        <span className="d-block mt-2 color-white h4">E-Commerce</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <RiComputerFill  />
                        <span className="d-block mt-2 color-white h4">Web Agency</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <IoMdCar/>
                        <span className="d-block mt-2 color-white h4">Auto Mobile</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <IoMdSettings/>
                        <span className="d-block mt-2 color-white h4">Software</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <FaPlane/>
                        <span className="d-block mt-2 color-white h4">Travel</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <MdHotel/>
                        <span className="d-block mt-2 color-white h4">Hotel</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                    <IoIosRocket />
                        <span className="d-block mt-2 color-white h4">Startups</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <GrGraphQl/>
                        <span className="d-block mt-2 color-white h4">Manufacturing</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <MdEventAvailable />
                        <span className="d-block mt-2 color-white h4">Event</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <GoGraph  />
                        <span className="d-block mt-2 color-white h4">Finance</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <GiShop />
                        <span className="d-block mt-2 color-white h4">Retail</span>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <GiTv />
                        <span className="d-block mt-2 color-white h4">Entertainment</span>
                    </li>
                </ul>
                </Slider>
            </React.Fragment>
        )
    }
}
export default Industries;