import React, { Component } from "react";


const PortfolioListContent = [
    {
        image: 'image-1',
        bigImage: '/assets/images/portfolio/projects/soy.png',
        category: 'E-Commerce',
        title: 'SOYLENT',
        link:"https://soylent.com/"
    },
    {
        image: 'image-2',
        bigImage: '/assets/images/portfolio/projects/biocoiff.png',
        category: 'E-Commerce',
        title: 'Biocoiff',
        link:"http://biocoiff.com/"
    },
    {
        image: 'image-4',
        bigImage: '/assets/images/portfolio/projects/cctv.png',
        category: 'E-Commerce',
        title: 'CCTVENGROS',
        link:"https://cctvengros.dk/"
    },
    {
        image: 'image-7',
        bigImage: '/assets/images/portfolio/projects/biocoiff-pro.png',
        category: 'E-Commerce',
        title: 'Biocoiff Pro',
        link:"https://www.biocoiff-pro.com/"
    },
    
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/studinthome.png',
        category: 'Real Estate',
        title: 'Studint',
        link:"https://studinthome.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/twd.png',
        category: 'Training',
        title: 'TWD',
        link:"https://www.trainwithdanny.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/bigyenmusic.png',
        category: 'Entertainment',
        title: 'Bigyen',
        link:"https://www.bigyenmusic.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/eastvalueresearch.png',
        category: 'Software',
        title: 'East Value Research',
        link:"https://eastvalueresearch.com/",
        
    },
    
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/mycashreward.png',
        category: 'E-Commerce',
        title: 'MyCash Reward',
        link:"https://mycashreward.co.uk/"
    },

    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/kidswonder.png',
        category: 'Education',
        title: 'Kids Wonder',
        link:"https://www.kidswonder.net/"
    },
    
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/publicimagemgmt.png',
        category: 'Entertainment',
        title: 'Public Image Management',
        link:"http://publicimagemgmt.com/"
    },
    
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/alexmariejordan.png',
        category: 'Entertainment',
        title: 'Alex Marie Jordan',
        link:"https://www.alexmariejordan.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/blackwomengunowners.png',
        category: 'Trainning',
        title: 'Black Women Gun Owners',
        link:""
    },
    
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/motionarray.png',
        category: 'E-Commerce',
        title: 'Motion Array',
        link:"https://motionarray.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/hottubaddict.png',
        category: 'Manufacturer',
        title: 'Hot Tub Addict',
        link:"https://hottubaddict.com/"
    },
    
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/baselayer.png',
        category: 'Food',
        title: 'Nom Noms',
        link:"https://dev1.baselayer.ca/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/menchies.png',
        category: 'Food',
        title: 'Menchies Frozen Yogurt',
        link:"https://www.menchies.com/",
        
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/prettygrafik.png',
        category: 'E-Commerce',
        title: 'Pretty Grafik',
        link:"https://prettygrafik.com/store/"
    },
    
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/vinnyrusso.png',
        category: 'Trainning',
        title: 'Vinnyrusso',
        link:"https://vinnyrusso.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/cncmasters.png',
        category: 'Manufacturer',
        title: 'CNC Masters',
        link:"https://www.cncmasters.com/"
    },

    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/housename.png',
        category: 'Real Estate',
        title: 'HouseName',
        link:"https://housename.ca/"
    },

]

class EcommercePortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail`} style={{backgroundImage:`url("${value.bigImage}")`}}>                                  
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <div className="title h4">{value.title}</div>
                                    <div className="portfolio-button">
                                        <a aria-label="portfolio-link" rel="nofollow noopener noreferrer"  target="_blank" href={value.link} className="rn-btn">View Details</a>
                                    </div>
                                </div>
                            </div>
                            <a aria-label="portfolio-link" rel="nofollow noopener noreferrer"  target="_blank" href={value.link} className="link-overlay"></a>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default EcommercePortfolioList;