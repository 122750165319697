// import React, { Component } from "react";
// import { Link } from "react-router-dom";

// class Breadcrumb extends Component{
//     render(){
//         const { title , parent,bg_image} = this.props;
//         return(
//             <React.Fragment>
//                 <div className={`breadcrumb-area rn-bg-color ptb--120 bg_image ${bg_image}`} data-black-overlay="6">
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-lg-12">
//                                 <div className="breadcrumb-inner pt--100">
//                                     <h1 className="title h2">{title}</h1>
//                                     <ul className="page-list">
//                                         <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
//                                         {parent? <li className="breadcrumb-item">{parent}</li>:''}
//                                         <li className="breadcrumb-item active">{title}</li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </React.Fragment>
//         )
//     }
// }

// export default Breadcrumb;

import React, { Component } from "react";
import { Link } from "react-router-dom";
// import '../../../public/assets/images/bg'

class Breadcrumb extends Component {
    render() {
        const { title, parent, bg_image } = this.props;

        // Construct the full URL for the background image
        const bgImageUrl = bg_image ? `${process.env.PUBLIC_URL}/assets/images/bg/${bg_image}.jpg` : '';
        console.log(bgImageUrl)

        return (
            <React.Fragment>
                <div 
                    className="breadcrumb-area rn-bg-color ptb--120" 
                    style={{
                        backgroundImage: `url(${bgImageUrl})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        minHeight: '200px', // Adjust as needed
                    }}
                    data-black-overlay="6"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner pt--100">
                                    <h1 className="title h2">{title}</h1>
                                    <ul className="page-list">
                                        <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                                        {parent ? <li className="breadcrumb-item">{parent}</li> : ''}
                                        <li className="breadcrumb-item active">{title}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Breadcrumb;
