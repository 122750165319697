import React, { Component } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col last-col ${branstyle}`}>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">WooCommerce</Tooltip>}
                    >
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/ecommerece/woocommerce.svg" alt="woocommerce"  width="92" height="55" />
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Shopify</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/ecommerece/shopify-2.svg" alt="shopify" width="150" height="43" />
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">OpenCart</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/ecommerece/opencart-svgrepo-com.svg" alt="opencart" width="80" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Magento</Tooltip>}
                    >
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/ecommerece/magento-2.svg" alt="magento" width="46" height="55"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">BigCommerce</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/ecommerece/bigcommerce-1.svg" alt="bigcommerce" width="80" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">PrestaShop</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/ecommerece/prestashop.svg" alt="prestashop" width="80" height="80"/>
                    </li>
                    </OverlayTrigger>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;