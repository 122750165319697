import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import Webdesign from "../elements/technologies/Webdesign";
import Projecttools from "../elements/technologies/Projecttools";
import Payment from "../elements/technologies/Payment";
import Opensource from "../elements/technologies/Opensource";
import Mobileapplication from "../elements/technologies/Mobileapplication";
import Microsoft from "../elements/technologies/Microsoft";
import Frontend from "../elements/technologies/Frontend";
import Ecommerce from "../elements/technologies/Ecommerce";
import Databasetechnologies from "../elements/technologies/Databasetechnologies";
import Crm from "../elements/technologies/Crm";
import Coderepository from "../elements/technologies/Coderepository";
import Cms from "../elements/technologies/Cms";
import Cloudsolution from "../elements/technologies/Cloudsolution";

function AsNavFor() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
    
  }, []);
  const techButtons = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    centerMode : true,
    arrows:false,
    responsive: [

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows:false,
          centerMode : false,
          

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows:false,
          centerMode : false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:true,
          focusOnSelect: true,
          centerMode : false,
        }
      }
    ]
  };

  return (
    <div className="slider-container tech-slider">
        <Slider asNavFor={nav2} 
        ref={slider => (sliderRef1 = slider)}
        {...techButtons}
        className="tech-buttons-slider"               
        >
        <div>
          <p className="tech-button">WEB DESIGN TOOLS</p>
        </div>
        <div>
          <p className="tech-button">FRONT END TECHNOLOGIES</p>
        </div>
        <div>
          <p className="tech-button">OPEN SOURCE & BACK END TECHNOLOGIES</p>
        </div>
        <div>
          <p className="tech-button">CONTENT MANAGEMENT SYSTEM</p>
        </div>
        <div>
          <p className="tech-button">E-COMMERCE PLATFORMS</p>
        </div>
        <div>
          <p className="tech-button">PAYMENT GATEWAY & API INTEGRATIONS</p>
        </div>
        <div>
          <p className="tech-button">MICROSOFT TECHNOLOGIES</p>
        </div>
        <div>
          <p className="tech-button">MOBILE APPLICATION TECHNOLOGIES</p>
        </div>
        <div>
          <p className="tech-button">DATABASE TECHNOLOGIES</p>
        </div>
        <div>
          <p className="tech-button">CLOUD SOLUTIONS</p>
        </div>
        <div>
          <p className="tech-button">CODE REPOSITORY</p>
        </div>
        <div>
          <p className="tech-button">CUSTOMER RELATIONSHIP MANAGEMENT</p>
        </div>
        <div>
          <p className="tech-button">PROJECT MANAGEMENT TOOLS</p>
        </div>
       
      </Slider>
    
      <Slider
   
        asNavFor={nav1}
        ref={slider => (sliderRef2 = slider)}
        slidesToShow={1}
        swipeToSlide={true}
        focusOnSelect={true}
        arrows={true}
        className="tech-image-slider"
      >
        <div>
            <Webdesign /> 
        </div>
        <div>
            <Frontend />  
        </div>
        <div>
            <Opensource /> 
        </div>
        <div>
            <Cms /> 
        </div>
        <div>
            <Ecommerce /> 
        </div>
        <div>
            <Payment /> 
        </div>
        <div>
            <Microsoft /> 
        </div>
        <div>
            <Mobileapplication /> 
        </div>
        <div>
            <Databasetechnologies /> 
        </div>
        <div>
            <Cloudsolution /> 
        </div>
        <div>
            <Coderepository /> 
        </div>
        <div>
            <Crm /> 
        </div>
        <div>
            <Projecttools /> 
        </div>
      </Slider>
    </div>
  );
}

export default AsNavFor;