import React, { Component } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list ${branstyle}`}>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Salesforce</Tooltip>}
                    >
                    <li>
                    <img src="/assets/images/technologies/crm/salesforce-2.svg" alt="salesforce" width="114" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Zoho</Tooltip>}
                    >
                    <li>
                    <img src="/assets/images/technologies/crm/zoho-1.svg" alt="zoho" width="150" height="52"/>
                    </li>
                    </OverlayTrigger>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;