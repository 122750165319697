import React, { Component } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col last-col ${branstyle}`}>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Wordpress</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/cms/wordpress-icon.svg" alt="wordpress" width="80" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Contentful</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/cms/contentful.svg" alt="contentful" width="71" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Webflow</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/cms/webflow-svgrepo-com.svg" alt="webflow" width="80" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Wix</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/cms/wix-svgrepo-com.svg" alt="wix" width="80" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Drupal</Tooltip>}
                    >
                    <li>
                         <img src="/assets/images/technologies/cms/drupal-2.svg" alt="drupal" width="150" height="38"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Squarespace</Tooltip>}
                    >
                    <li>
                    <img src="/assets/images/technologies/cms/squarespace-one.svg" alt="squarespace" width="80" height="80"/>
                    </li>
                    </OverlayTrigger>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;