import React, { Component } from "react";

const PortfolioListContent = [
    {
        image: 'image-4',
        bigImage: '/assets/images/portfolio/projects/cctv.png',
        category: 'E-Commerce',
        title: 'CCTVENGROS',
        link:"https://cctvengros.dk/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/mycashreward.png',
        category: 'E-Commerce',
        title: 'MyCash Reward',
        link:"https://mycashreward.co.uk/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/havenunderdeck.png',
        category: 'Business',
        title: 'Havenunderdeck',
        link:"https://havenunderdeck.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/menchies.png',
        category: 'Food',
        title: 'Menchies Frozen Yogurt',
        link:"https://www.menchies.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/225liveevents.png',
        category: 'Business',
        title: '225 Live Events',
        link:"https://www.225liveevents.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/virtualmedicare.png',
        category: 'Hospital',
        title: 'Medicare App',
        link:"https://www.virtualmedicare.com/"
    },
    {
        image: 'image',
        bigImage: '/assets/images/portfolio/projects/solcreative.png',
        category: 'Web Agency',
        title: 'Sol Creative',
        link:"https://solcreative.ca/"
    },
]

class CustomphpPortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail`} style={{backgroundImage:`url("${value.bigImage}")`}}>                                  
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <div className="title h4">{value.title}</div>
                                    <div className="portfolio-button">
                                        <a aria-label="portfolio-link" rel="nofollow"  target="_blank" href={value.link} className="rn-btn">View Details</a>
                                    </div>
                                </div>
                            </div>
                            <a aria-label="portfolio-link" rel="nofollow"  target="_blank" href={value.link} className="link-overlay"></a>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default CustomphpPortfolioList;