import React, { Component } from "react";
import {FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";

import { Link } from 'react-router-dom';
const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/rigicglobalsolutions/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/rigic-global-solutions-private-limited-3b468b1a5/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/rigicglobalsolutions/?hl=en/'},
    {Social: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" stroke="currentColor" fill="currentColor" strokeWidth="0" height="1em" width="1em"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg> , link: 'https://twitter.com/rigicglobal'},
]

class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area bg_color-transpent">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Ready To Do This</span>
                                        <div className="h2">Let's get <br /> to work</div>
                                        <a aria-label="contact-us" className="rn-button-style--2" href="/contact">
                                            <span>Contact Us</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                <div className="h4">Quick Link</div>
                                                <ul className="ft-link">
                                                    <li><Link aria-label="home" to="/">Home</Link></li>
                                                    <li><Link aria-label="about" to="/about-us">About Us</Link></li>
                                                    <li><Link aria-label="services" to="/services">Services</Link></li>
                                                    <li><Link aria-label="portfolio" to="/portfolio">Portfolio</Link></li>
                                                    <li><Link aria-label="contact" to="/contact">Contact Us</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <div className="h4">Say Hello</div>
                                                <ul className="ft-link">
                                                    <li><a aria-label="email-contact" href="mailto:info@rigicglobalsolutions.com">info@rigicglobalsolutions.com</a></li>
                                                    <li><a aria-label="tel-contact" href="tel:+919737143243">+91 973-714-3243</a></li>
                                                    <li><a aria-label="phone-contact" href="tel:+919638464855">+91 963-846-4855</a></li>
                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a aria-label="Social-Link" rel="noopener noreferrer" target="_blank"  href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text d-flex">
                                                <p>©2024 Rigic Global Solutions Pvt. Ltd. | All Rights Reserved </p>
                                                <a aria-label="privacy-policy" href="/privacy-policy">Privacy Policy</a>
                                                <a aria-label="terms-condition" href="/terms-condition">Terms & Condition</a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;