import React, { Component } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col last-col ${branstyle}`}>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">HTML 5</Tooltip>}
                    >
                    <li>
                        <img className="" src="/assets/images/technologies/frontend/html5-2.svg" alt="html5" width="57" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">CSS 3</Tooltip>}
                    >
                    <li>
                        <img className="" src="/assets/images/technologies/frontend/css-3-logo-svgrepo-com.svg" alt="css" width="80" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Bootstrap</Tooltip>}
                    >
                    <li>
                        <img className="" src="/assets/images/technologies/frontend/bootstrap.svg" alt="bootstrap" width="80" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Vue.js</Tooltip>}
                    >
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/frontend/vue-js-icon.svg" alt="vue" width="64" height="55"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">jQuery</Tooltip>}
                    >
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/frontend/jquery-1.svg" alt="jquery" width="150" height="34"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">React</Tooltip>}
                    >
                    <li>
                        <img className="" src="/assets/images/technologies/frontend/react-js-icon.svg" alt="React" width="80" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Angular</Tooltip>}
                    >
                    <li>
                        <img className="" src="/assets/images/technologies/frontend/angular-icon.svg" alt="angular" width="74" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Tailwind</Tooltip>}
                    >
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/frontend/tailwind-css-icon.svg" alt="tailwind" width="92" height="55"/>
                    </li>
                    </OverlayTrigger>

                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;