import React, { Component } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

class Webdesign extends Component {
  render() {
    const { branstyle } = this.props;
    return (
      <React.Fragment>
        <ul className={`brand-list three-col last-col ${branstyle}`}>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">Figma</Tooltip>}
          >
            <li>
              <img
                src="/assets/images/technologies/webdesign/figma.svg"
                alt="figma"
                width="80"
                height="80"
              />
            </li>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">Adobe Photoshop</Tooltip>}
          >
          <li>
            <img
              src="/assets/images/technologies/webdesign/photoshop.svg"
              alt="photoshop"
              width="80"
              height="80"
            />
          </li>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">Adobe XD</Tooltip>}
          >
          <li>
            <img
              src="/assets/images/technologies/webdesign/adobe-xd.svg"
              alt="adobe"
              width="80"
              height="80"
            />
          </li>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">Adobe Illustrator</Tooltip>}
          >
          <li>
            <img
              src="/assets/images/technologies/webdesign/adobe-illustrator-icon.svg"
              alt="Adobe_Illustrator"
              width="80"
              height="80"
            />
          </li>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">Canva</Tooltip>}
          >
          <li>
            <img
              src="/assets/images/technologies/webdesign/canva-1.svg"
              alt="canva"
              width="80"
              height="80"
            />
          </li>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">Invision</Tooltip>}
          >
          <li>
            <img
              src="/assets/images/technologies/webdesign/invision.svg"
              alt="invision"
              width="80"
              height="80"
            />
          </li>
          </OverlayTrigger>
        </ul>
      </React.Fragment>
    );
  }
}
export default Webdesign;
