import React, { Component } from "react";
import { RiCodeSSlashFill } from "react-icons/ri";
import { GiPaintBrush } from "react-icons/gi";
import { FaPalette } from "react-icons/fa";
import { BiCodeBlock } from "react-icons/bi";
import { FaWallet } from "react-icons/fa";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { FaFillDrip } from "react-icons/fa";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { FaRegCommentDots } from "react-icons/fa";
import { FaMicrosoft } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaChalkboardTeacher } from "react-icons/fa";
// import { AiOutlineOpenAI } from "react-icons/ai";

const ServiceList = [
    {
        icon: <FaPalette  />,
        title: 'Graphic Design',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FaMicrosoft />,
        title: 'Microsoft Technologies',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <GiPaintBrush  />,
        title: 'Web Design',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <HiOutlineDocumentDuplicate  />,
        title: 'Template-Based Solutions',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    
    {
        icon: <RiCodeSSlashFill  />,
        title: 'Open Source Development',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FaFillDrip   />,
        title: 'UI/UX Design',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <BiCodeBlock   />,
        title: 'Custom Website Development',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },    

    {
        icon: <FaEnvelopeOpenText  />,
        title: 'Digital Marketing',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FaWallet     />,
        title: 'E-Commerce Solutions',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FaChalkboardTeacher  />,
        title: 'IT Consulting',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        icon: <FaMobileAlt  />,
        title: 'Mobile App Development',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    
    {
        icon: <FaRegCommentDots  />,
        title: 'Support & Maintenance',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'SERVICES',
        description = "Your website is the heart of your business. It's how your customers know you and connect with you. So we design your site with your clients in mind. We integrate design that attracts and retains customers with functions that solve their problems.";
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <div className="section-title mt--30 mb--30 mt_md--5 mt_mobile--5 mb_mobile--10 text-center">
                            <p>DESIGN. TECHNOLOGY. CUSTOMIZATION.</p>
                            <div className="title h2 custom-heading color-white">{title}</div>
                            <p>{description}</p>
                        </div>
                    </div>
                    <div className="col-lg-12 col-12 mt_md--50 custom-service-wrapper">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    
                                        <div className="service service__style--2 align-items-center">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <div className="title text-center text-md-left h3 color-white tech-title">{val.title}</div>
                                                {/* <p>{val.description}</p> */}
                                            </div>
                                        </div>
                                    
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
