import React, { Component } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col last-col ${branstyle}`}>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Stripe</Tooltip>}
                    >
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/payment/stripe-4.svg" alt="stripe" width="132" height="55"/>  
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">PayPal</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/payment/paypal-3.svg" alt="PayPal" width="150" height="40"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">.NET</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/payment/dot-net-svgrepo-com.svg" alt=".NET" width="80" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Square</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/payment/payment-square-svgrepo-com.svg" alt="payment" width="80" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Braintree</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/payment/Braintree_(company)-Logo.wine.svg" alt="Braintree_" width="120" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Razorpay</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/payment/razorpay.svg" alt="razorpay" width="150" height="32"/>
                    </li>
                    </OverlayTrigger>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;