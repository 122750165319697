import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'ABOUT RIGIC GLOBAL',
        shortdescription = "We Innovate. We Create. We stay ahead of the Curve.",
        description = "Our team of graphic designers, UX specialists, coders and digital marketing experts deliver the exceptional results you deserve.";
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-43.jpg" alt="About Rigic" width="472" height="627" loading="lazy"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title h2">{title}</h2>
                                        <p>We <span className="bold-text theme-color">Innovate. </span> We <span className="bold-text theme-color">Create. </span> We stay ahead of the <span className="bold-text theme-color">Curve. </span></p>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <div className="title h3-title h3">Cutting-Edge Framework</div>
                                                <p>We provide the technology to drive your site to success with use of latest frameworks.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <div className="title h3-title h3">Customized Approach</div>
                                                <p>Complete customization in real time. So every element of your site looks just the way you want it.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;