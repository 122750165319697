import React, { Component,Suspense } from "react";
import PageHelmet from "../component/common/Helmet";
import Loader from "../component/loader/Loader";
import Breadcrumb from "../elements/common/Breadcrumb";
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiMonitor,
  FiChevronUp,
} from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Button from "react-bootstrap/Button";
import { MdMailOutline } from "react-icons/md";
import { RiCodeSSlashFill } from "react-icons/ri";
import { GiPaintBrush } from "react-icons/gi";
import { FaPalette } from "react-icons/fa";
import { BiCodeBlock } from "react-icons/bi";
import { FaWallet } from "react-icons/fa";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { FaRegCommentDots } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaFillDrip } from "react-icons/fa";

import { FaMicrosoft } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaChalkboardTeacher } from "react-icons/fa";
const baseURL = process.env.REACT_APP_API_URL;
const OtherComponent = React.lazy(() =>
  import("./../elements/common/Breadcrumb")
);
const ServiceList = [
  {
    icon: <FaPalette />,
    title: "Graphic Design",
    description:
      "Stunning. Memorable. Clear. Our professional artists use color, shapes, images and typography to make your brand shine, both online and in print. Get best UIUX experience with Rigic Global and make your brand more stronger.",
  },
  {
    icon: <GiPaintBrush />,
    title: "Web Design",
    description:
      "Want a great website? You need great design. Our team of innovative designers create intuitive sites that provide a best-in-class user experience. We put your audience first. Because they matter most to you, they matter most to us.",
  },
  {
    icon: <RiCodeSSlashFill />,
    title: "Open Source Development",
    description:
      "We save you money through the use of open source technologies which do not require license fees. CMS and open-source tech empower easy content management with platforms like WordPress, Laravel & many more. Free, collaborative, and customizable, they drive digital innovation for businesses and content creators.",
  },
  {
    icon: <BiCodeBlock />,
    title: "Custom Website Development",
    description:
      "We believe websites should perform as beautifully as they look. So we optimize all sites for responsive use across all devices. Whether you need a simple single-page or a complex eCommerce site, we deliver with organized code, flawless performance and extensive validation.",
  },
  {
    icon: <FaWallet />,
    title: "E-Commerce Solutions",
    description:
      "We help you sell and we help you scale. Our customized e-commerce solutions choose just the right platform and features for your unique business, whether you are completely online or using your website as an extension of your brick-and-mortar location.",
  },
  {
    icon: <FaMobileAlt />,
    title: "Mobile App Development",
    description:
      "Crafting the future in the palm of your hand! Our mobile app development services with iOS and Android OS redefine user experiences, blending innovation with functionality. From conceptualization to launch, we bring your vision to life on every device.",
  },
  {
    icon: <FaMicrosoft />,
    title: "Microsoft Technologies",
    description:
      "Microsoft Technologies encompass a suite of powerful tools and platforms, spanning from the widely-used Windows operating system to innovative cloud solutions like Azure.",
  },

  {
    icon: <HiOutlineDocumentDuplicate />,
    title: "Template-Based Solutions",
    description:
      "Our customized templates and frameworks let you collect data and streamline tasks (think invoices,payments,etc) accurately and efficiently. So you can focus your resources on more important tasks — and on your customers.",
  },

  {
    icon: <FaFillDrip />,
    title: "UI/UX Design",
    description:
      "UI/UX Design, the art of blending user interface (UI) with user experience (UX), transforms digital interactions into seamless journeys. It's about more than just aesthetics; it's crafting intuitive interfaces that delight users and drive engagement.",
  },

  {
    icon: <FaEnvelopeOpenText />,
    title: "Digital Marketing",
    description:
      "Your marketing strategy determines your success. We listen to you to understand your goals, your products and your opportunities. Then our digital marketing experts develop the strategies you need to maximize ROI and make every marketing effort count.",
  },

  {
    icon: <FaChalkboardTeacher />,
    title: "IT Consulting",
    description:
      "IT consulting at Rigic Global involves advising businesses on utilizing technology to achieve their goals efficiently. We as a consultant offers expertise in areas like software, hardware, networks, and cybersecurity to optimize IT infrastructure and operations.",
  },
  {
    icon: <FaRegCommentDots />,
    title: "Support & Maintenance",
    description:
      "The internet is always available. So we are, too. Even after your website is up and running. We are here to keep things flowing smoothly, to help you scale as your goals expand and to support your business in every possible way. 24/7. Because we know success does not sleep.",
  },
];
class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loader: false,
      msg: false,
    };
  }

  handleChange(e) {
    this.setState({ email: e.target.value });
  }
  render() {
    const handleOnSubmit = (e) => {
      e.preventDefault();
      this.setState({ loader: true });
      fetch(baseURL + "subscribenewsletter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.state.email,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          this.setState({ loader: false });
          this.setState({ email: "" });
          this.setState({ msg: true });
        });
    };
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Service" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        {/* <Breadcrumb title={"Services"} bg_image={"bg_image--12"} /> */}
        <Suspense fallback={<div>Loading...</div>}>
          <OtherComponent  title={"Services"} bg_image={"bg-image-12"} />
        </Suspense>
        {/* End Breadcrump Area */}

        {/* Start Service Area */}
        <div className="service-area ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <p className="sub-heading-text text-uppercase">
                    Our Services will help you to meet your Vision
                  </p>
                  <div className="title custom-heading h2">
                    WE HELP YOU TO BUILD BRANDS
                  </div>
                </div>
              </div>
            </div>
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div
                  className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <div className="service service__style--2">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <div className="title tech-title h3">{val.title}</div>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start Service Area */}
        {/* <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Strategy</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
        {/* End Service Area */}

        {/* Start Service Area */}
        {/* <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Creative Agency</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
        {/* End Service Area */}

        {/* Start Service Area */}
        {/* <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Development</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
        {/* End Service Area */}

        {/* Start Newsletter Area  */}

        <section className="pb--120 newsletter-container subscribe-bottom-space">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="title custom-heading h2 text-center text-uppercase">
                  Subscribe to Our Newsletter
                </div>
                <p className="text-center">
                  Want to stay up-to-date on the latest in website design and
                  technology? We'll make sure you never fall behind the curve,
                  with updates arriving fresh in your inbox.
                </p>

                <form action="" onSubmit={handleOnSubmit}>
                  <div className="rn-form-group mb-3 input-group">
                    <input
                      type="email"
                      name="email"
                      placeholder="* Your Email"
                      value={this.state.email}
                      required
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      //onChange={e => setEmail(e.target.value)} value={email}
                      className="form-control"
                    />
                    <Button variant="" id="button-addon2" type="submit">
                      <MdMailOutline />
                    </Button>
                  </div>
                </form>
                {this.state.loader ? <Loader /> : ""}
                {this.state.msg ? (
                  <div className="text-center  alert alert-success newsletter-succes-text">
                    We promise to send you only the best stuff — no spam, no
                    junk, just the good stuff! And if you ever find yourself
                    missing out on our awesome content, don’t worry, we’ll just
                    assume you’re busy being a superhero or something.
                  </div>
                ) : (
                  ""
                )}
                <span className="text-center d-block privacy-bottom-text">
                  * We don't share your personal info with anyone. Check out our{" "}
                  <Link
                    className="text-decoration-underline"
                    to="/privacy-policy"
                  >
                    Privacy Policy
                  </Link>{" "}
                  for more information.
                </span>
              </div>
            </div>
          </div>
        </section>

        {/* End  Newsletter Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Service;
