import React, { Component } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col last-col ${branstyle}`}>
                    <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="button-tooltip">Monday</Tooltip>}
                        >
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/project/monday-1.svg" alt="monday" width="89" height="55"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="button-tooltip">Slack</Tooltip>}
                        >
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/project/slack-new-logo.svg" alt="slack" width="55" height="55"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="button-tooltip">Trello</Tooltip>}
                        >
                    <li>
                        <img src="/assets/images/technologies/project/trello-logo.svg" alt="Trello" width="150" height="43"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="button-tooltip">Jira</Tooltip>}
                        >
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/project/jira-1.svg" alt="jira" width="55" height="55"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="button-tooltip">Basecamp</Tooltip>}
                        >
                    <li>
                        <img src="/assets/images/technologies/project/basecamp-logo-2019.svg" alt="basecamp" width="150" height="30"/>
                    </li>
                    </OverlayTrigger>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;