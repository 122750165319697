import React, { Component } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col ${branstyle}`}>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Amazon Web Services</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/cloud/amazon-web-services.svg" alt="amazon" width="150" height="56"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Google Cloud</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/cloud/google-cloud-3.svg" alt="google cloud" width="150" height="26"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">DigitalOcean</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/cloud/digitalocean.svg" alt="digitalocean" width="125" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Microsoft Azure</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/cloud/azure-icon-svgrepo-com.svg" alt="azure" width="80" height="80"/>
                    </li>
                    </OverlayTrigger>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;