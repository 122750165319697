import React, { Component } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col ${branstyle}`}>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">MySQL</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/database/mysql-logo-svgrepo-com.svg" alt="mysql" width="80" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">MariaDB</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/database/mariadb.svg" alt="mariadb"  width="120" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">MongoDB</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/database/mongodb-svgrepo-com.svg" alt="mongodb"  width="80" height="80"/>
                    </li>
                    </OverlayTrigger>
                    <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Microsoft SQL Server</Tooltip>}
                    >
                    <li>
                        <img src="/assets/images/technologies/database/microsoft-sql-server-1.svg" alt="microsoft"  width="125" height="80"/>
                    </li>
                    </OverlayTrigger>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;