import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Accordion from 'react-bootstrap/Accordion';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class TermsCondition extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Privacy' />
                
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />


                <div className="pt-5 pb--120 terms-wrapper">
                    <div className="container pt--120">
                        <div className="row">
                            <div className="col-12 custom-accordion">
                                <h2 className="text-center custom-heading mb-4">Terms & Condition</h2>
                                <p><a className="theme-colors" href="https://rigicglobalsolutions.com/">Rigic Global Solutions Private Limited</a> (here in after RGSPL) maintains this Site for information about our product and services. Please feel free to browse the Site.</p>
                                <p>Your access and use of the Site is also subject to the following terms and conditions and future revisions if any, (“Terms and Conditions”) and all applicable laws. By accessing and browsing the Site, you accept the Terms and Conditions below.</p>

                                <ul>
                        <li className="padding-10">The Site, and all the content, materials, information, software, products
                            and services provided on the Site, are provided on an “as is” and “as available” basis.
                            RGSPL expressly disclaims all warranties of any kind, whether express or implied, including,
                            but not limited to, the implied warranty of merchantability, fitness for a particular
                            purpose and non-infringement.
                        </li>
                        <li className="padding-10">The Content and the projects described or depicted on the Site are
                            subject to change without notice. Copyright may exist on any future materials. Unauthorized
                            recording of such materials would be contrary to applicable copyright laws.</li>
                        <li className="padding-10">RGSPL assumes no responsibility, and shall not be liable for, any damages
                            to, or viruses that may infect, your computer, equipment or other property on account of
                            your access to, use of or browsing the Site or your downloading of any materials, data,
                            text, images, video, or audio from the Site.</li>
                        <li className="padding-10">Materials displayed on the Site are either the property of, or used with
                            permission by RGSPL. The use of these materials by you or anyone else authorized by you is
                            prohibited unless expressly permitted by these Terms and Conditions or express written
                            permission is obtained from RGSPL. Any unauthorized use of the images may violate copyright
                            laws, trademark laws, the laws of privacy and publicity.</li>
                        <li className="padding-10">RGSPL is not responsible for the content of any other site linked to or
                            from the Site. Your linking to any other site/sites is entirely at your own risk. While
                            RGSPL may provide links on the Site to other sites, the inclusion of such links is only for
                            your convenience and should not be interpreted as an endorsement of the owner/sponsor of the
                            site or the content on the other site/s. Subject to the Non-Excludable Rights (above), RGSPL
                            disclaims all warranties, express and implied, as to the accuracy, validity, legality or
                            otherwise of any materials or information contained on such sites.</li>
                        <li className="padding-10">You are prohibited from posting or transmitting any unlawful,
                            threatening, harassing, defamatory, libelous, obscene, pornographic or profane material or
                            any material that could constitute or encourage conduct that would be considered a criminal
                            offence or give rise to civil liability or otherwise violate any law.</li>
                        <li className="padding-10">RGSPL may at any time revise these Terms and Conditions by updating this
                            post. Since you are bound by these Terms and conditions, please visit this page regularly to
                            learn about the revised Terms and Conditions if any.</li>
                        <li className="padding-10">The Agreement and all disputes shall be subject to Ahmedabad jurisdiction
                            only.</li>
                    </ul>


                            </div>
                        </div>
                    </div>
                </div>

          
                
                <Footer/>
                
            </React.Fragment>
        )
    }
}
export default TermsCondition